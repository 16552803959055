.accordion-item {
    // border: 1px solid #ccc;
    // border-radius: 4px;
    margin-bottom: 1px;
    width: 80%;
    align-self: center;
    // font-size: 18px;
    
  }
  
  .accordion-title {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #b0b0b0;
    height: 50px;
    align-items: center;
    font-size: 18px;
    color: #14141a;
    cursor: pointer;
  }
  
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-out, padding 1s ease-out;
    display: grid;
    align-items: center;
    justify-content: space-between;
    img{
      width: 100%;
    }
    .v1{
      justify-content: center;
      align-items: center;
      width: 250px;
      text-align: center;
      height: 70px;
      display: flex;
      justify-items: center;
    }
    .cont{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;

    }

  }
 
  
  .accordion-content.active {
    max-height: 3030px; /* Adjust this value to fit your content */
    padding: 10px;
  }
   .right{
    grid-template-columns: 40% 50%;

      @media (max-width: 700px){
        display: flex;
        flex-direction: column;
    }
    

  }
  .left{
    grid-template-columns: 50% 40%;
    @media (max-width: 700px){
      display: flex;
      flex-direction: column-reverse;
    }

  }
  .children{
    justify-items: center;
  }