.second_screen{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 50px;
    .content{
        z-index: 20;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 30px;
        align-items: center;
        @media (max-width: 700px){
            display: flex;
            flex-direction: column;
        }
        img{
            width: 100%;
        }
        p{
            text-align:justify;
            text-align:center;
            font-size: 20px;
        }
        .leftC{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}