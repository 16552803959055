@use "_header";
@use "_sideDrawer";
// @use "_calc";
// @use "_footer.";
// // @use "services";
@use "_accordion";
@use "_firstScreen";
@use "_secondScreen";
@use "_thirdScreen";
@use "_footer";
// html {
//     scroll-behavior: smooth;
//   }

@font-face {
  font-family: "helvetica_bold";
  src: url("../Fonts/helvetica_bold.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald";
  src: url("../Fonts/Oswald-VariableFont_wght.ttf") format("truetype");
}
// @font-face {
//     font-family: 'Montserrat-Light';
//     src: url('../Fonts/Montserrat-Light.ttf') format('truetype');
// }
// @font-face {
//     font-family: 'Montserrat-Medium';
//     src: url('../Fonts/Montserrat-Medium.ttf') format('truetype');
// }
// @font-face {
//     font-family: 'Montserrat-Regular';
//     src: url('../Fonts/Montserrat-Regular.ttf') format('truetype');
// }
// @font-face {
//     font-family: 'Montserrat-SemiBold';
//     src: url('../Fonts/Montserrat-SemiBold.ttf') format('truetype');
// }

body {
  // margin: 0;
  // padding: 0;
  // background-color: rgb(235, 235, 235);
  background-color: #4e9b9b;
  font-family: "Oswald";
  // @media (max-width: 700px) {
  //   align-items: center;
  //   display: flex;
  //   flex-direction: column;
  width: 100vw;
  // }
}

.App {
  background-color: #fdffff;
  display: flex;
  flex-direction: column;
  // max-width: 600px;
  width: 100vw;
  @media (max-width: 700px) {
    align-items: center;
    margin: 0;
    padding: 0;
  }
}

.orange {
  color: rgba(243, 194, 60, 1);
}
.grey {
  color: #aaa9ad;
}

.button {
  padding: 20px 80px;
  font-size: 18px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.v1 {
  background-color: rgba(243, 194, 60, 1);
  color: #444444;
  // color: #f3c23c;
}
.v2 {
  background-color: #444444;
  color: rgba(243, 194, 60, 1);
  border-left: 2px solid rgba(243, 194, 60);
  border-right: 2px solid rgba(243, 194, 60);
}

.pulse-button {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: 100px;
  padding: 10px;
  border: none;
  background: #32eb79;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  outline: none;
  z-index: 1000;
}

.pulse-button__icon {
  display: block;
  width: 40px;
  height: 40px;
  transition: 0.3s;
}

.pulse-button__text {
  display: block;
  // width: 100%;
  height: 30px;
  // font: 15px 'Open Sans', sans-serif;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 1.2;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  position: absolute;
  transition: 0.3s;
  margin-top: 1px;
}

.pulse-button:hover .pulse-button__icon {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transition: 0.3s;
}

.pulse-button:hover .pulse-button__text {
  transition: 0.3s;
  opacity: 1;
  visibility: visible;
  position: relative;
}

.pulse-button__rings {
  border: 2px solid #32f57d;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  width: auto;
  border-radius: 50%;
  animation-duration: 2s;
  animation-name: pulse_1;
  animation-iteration-count: infinite;
  z-index: -1;
}

.pulse-button__rings:nth-child(2) {
  animation-name: pulse_2;
}

.pulse-button__rings:nth-child(3) {
  animation-name: pulse_3;
}

@keyframes pulse_1 {
  from {
    opacity: 1;
    transform: scale(0.9, 0.9);
  }

  to {
    opacity: 0;
    transform: scale(1.3, 1.3);
  }
}

@keyframes pulse_2 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }

  to {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }
}

@keyframes pulse_3 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }

  to {
    opacity: 0;
    transform: scale(1.7, 1.7);
  }
}
