.footer {
  background-color: #444444;
  // height: 100px;
  padding: 20px;
  margin-top: 50px;
}
.footer_content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 30px;
  .footer_title {
    color: white;
    text-align: center;
    max-width: 95vw;
    font-weight: 100;
    font-size: 24px;
  }
  .devoleper_content {
    a {
      font-size: 20px;
      color: #f3c23c;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
