.side-drawer {
    height: 100%;
    background: #444444;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    max-width: 70%;
    z-index: 200;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    img{
      height: 75px;
      margin: 0;
    }
  }

  .drawer-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }

  .close-button {
  background: none;
  border: none;
  cursor: pointer;
}

  .side-drawer.open {
    transform: translateX(0);
  }
  
  .side-drawer ul {
    // height: 50%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  
  .side-drawer li {
    margin: 0.5rem 0;
  }
  
  .side-drawer a {
    color: #888888;
    text-decoration: none;
    font-size: 1.2rem;
  }
  
  .side-drawer a:hover,
  .side-drawer a:active {
    color: rgba(243, 194, 60, 1);
  }