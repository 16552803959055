.header {
  background-color: #2f343a;
  // background-color: #e68585;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;

  @media (max-width: 700px){
    background-color: #444444;
    margin: 0;
    padding: 0;
    // width: 105%;

}
//   padding: 0 10px;
  .menu{
    padding: 15px;
    display: flex;
    gap: 30px;
    cursor: pointer;
  }
  a{
    // font-size: 12px;
    text-transform: none;
    text-decoration: none;
    color: #aaa9ad;
    cursor: pointer;
    transition: color 0.3s ease;
    @media (max-width: 700px){
      font-size: 24px;
    }
  }
  a:active, a:hover {
    color: rgba(243, 194, 60, 1);
  }

  .white{
    color: white;
  }
  .tel{
    width: 250px;
    display: flex;
    justify-content: center;

  }
  .logo {
    height: 50px;
  }
}
